<template>
  <div id="root" class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to reset your password?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="reset()" class="edit">Reset</button>
        </div>
      </div>
    </div>

    <h1 class="heading-page">Login</h1>
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <label class="text-left">Email:</label>
          <input class="mb-3" v-model="email" type="email" value="" />
          <label class="text-left">Password:</label>
          <input @keyup.enter="submit()" class="mb-3" v-model="password" type="password" value="" />
          <div v-bind:class="[isMobile() ? 'v-stack gap-3' : 'h-stack h-space-between']">
            <button @click="resetDialog()" class="edit">Reset Password</button>
            <button @click="submit()" class="submit">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/eventbus.js";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["login", "resetPassword"]),
    isMobile() {
      return this.$isMobile()
    },
    submit() {
      this.login({
        email: this.email.toLowerCase(),
        password: this.password,
      })
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    reset() {
      this.dismiss();
      this.resetPassword({
        email: this.email.toLowerCase(),
      })
        .then(() => {
          EventBus.$emit("message", {
            text: "Password reset",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
